import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NativeFeatureIdentifier, FeatureStatus } from '@standardnotes/snjs';
import TwoFactorAuthWrapper from './TwoFactorAuth/TwoFactorAuthWrapper';
import Encryption from './Encryption';
import PasscodeLock from './PasscodeLock';
import Privacy from './Privacy';
import Protections from './Protections';
import ErroredItems from './ErroredItems';
import PreferencesPane from '@/Components/Preferences/PreferencesComponents/PreferencesPane';
import BiometricsLock from '@/Components/Preferences/Panes/Security/BiometricsLock';
import MultitaskingPrivacy from '@/Components/Preferences/Panes/Security/MultitaskingPrivacy';
import U2FWrapper from './U2F/U2FWrapper';
const Security = (props) => {
    const isNativeMobileWeb = props.application.isNativeMobileWeb();
    const isU2FFeatureAvailable = props.application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.UniversalSecondFactor).getValue()) === FeatureStatus.Entitled && props.application.sessions.getUser() !== undefined;
    return (_jsxs(PreferencesPane, { children: [_jsx(Encryption, {}), props.application.items.invalidNonVaultedItems.length > 0 && _jsx(ErroredItems, {}), _jsx(Protections, { application: props.application }), _jsx(TwoFactorAuthWrapper, { application: props.application }), isU2FFeatureAvailable && _jsx(U2FWrapper, { application: props.application }), isNativeMobileWeb && _jsx(MultitaskingPrivacy, { application: props.application }), _jsx(PasscodeLock, { application: props.application }), isNativeMobileWeb && _jsx(BiometricsLock, { application: props.application }), props.application.sessions.getUser() && _jsx(Privacy, { application: props.application })] }));
};
export default Security;
