import { action, makeAutoObservable, observable } from 'mobx';
import { TwoFactorActivation } from './TwoFactorActivation';
export const is2FADisabled = (status) => status === 'two-factor-disabled';
export const is2FAActivation = (status) => (status === null || status === void 0 ? void 0 : status.type) === 'two-factor-activation';
export const is2FAEnabled = (status) => status === 'two-factor-enabled';
export class TwoFactorAuth {
    constructor(sessions, mfa) {
        this.sessions = sessions;
        this.mfa = mfa;
        this._status = 'fetching';
        this._errorMessage = null;
        makeAutoObservable(this, {
            _status: observable,
            _errorMessage: observable,
            deactivateMfa: action,
            startActivation: action,
        }, { autoBind: true });
    }
    startActivation() {
        const setDisabled = action(() => (this._status = 'two-factor-disabled'));
        const setEnabled = action(() => {
            this._status = 'two-factor-enabled';
            this.fetchStatus();
        });
        this.mfa
            .generateMfaSecret()
            .then(action((secret) => {
            var _a;
            this._status = new TwoFactorActivation(this.mfa, (_a = this.sessions.getUser()) === null || _a === void 0 ? void 0 : _a.email, secret, setDisabled, setEnabled);
        }))
            .catch(action((e) => {
            this.setError(e.message);
        }));
    }
    deactivate2FA() {
        this.mfa
            .disableMfa()
            .then(action(() => {
            this.fetchStatus();
        }))
            .catch(action((e) => {
            this.setError(e.message);
        }));
    }
    isLoggedIn() {
        return this.sessions.getUser() != undefined;
    }
    fetchStatus() {
        if (!this.isLoggedIn()) {
            return;
        }
        this.mfa
            .isMfaActivated()
            .then(action((active) => {
            this._status = active ? 'two-factor-enabled' : 'two-factor-disabled';
            this.setError(null);
        }))
            .catch(action((e) => {
            this._status = 'two-factor-disabled';
            this.setError(e.message);
        }));
    }
    setError(errorMessage) {
        this._errorMessage = errorMessage;
    }
    toggle2FA() {
        if (!this.isLoggedIn()) {
            return;
        }
        if (this._status === 'two-factor-disabled') {
            return this.startActivation();
        }
        if (this._status === 'two-factor-enabled') {
            return this.deactivate2FA();
        }
    }
    get errorMessage() {
        return this._errorMessage;
    }
    get status() {
        return this._status;
    }
}
