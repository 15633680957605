import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { TwoFactorAuth } from './TwoFactorAuth';
import TwoFactorAuthView from './TwoFactorAuthView/TwoFactorAuthView';
const TwoFactorAuthWrapper = (props) => {
    const [auth] = useState(() => new TwoFactorAuth(props.application.sessions, props.application.mfa));
    auth.fetchStatus();
    return _jsx(TwoFactorAuthView, { auth: auth, application: props.application });
};
export default TwoFactorAuthWrapper;
