import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon/Icon';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator/KeyboardShortcutIndicator';
import MenuItem from '../Menu/MenuItem';
import { iconClass } from './ClassNames';
import MenuSection from '../Menu/MenuSection';
const SuperNoteOptions = ({ markdownShortcut, enableSuperMarkdownPreview }) => {
    return (_jsx(MenuSection, { children: _jsxs(MenuItem, { onClick: enableSuperMarkdownPreview, children: [_jsx(Icon, { type: "markdown", className: iconClass }), "Show Markdown", markdownShortcut && _jsx(KeyboardShortcutIndicator, { className: "ml-auto", shortcut: markdownShortcut })] }) }));
};
export default SuperNoteOptions;
