import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertStringifiedBooleanToBoolean, isDesktopApplication } from '@/Utils';
import { STRING_FAILED_TO_UPDATE_USER_SETTING } from '@/Constants/Strings';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import Dropdown from '@/Components/Dropdown/Dropdown';
import Switch from '@/Components/Switch/Switch';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import { EmailBackupFrequency, MuteFailedBackupsEmailsOption, SettingName } from '@standardnotes/snjs';
import PreferencesGroup from '../../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../../PreferencesComponents/PreferencesSegment';
import Spinner from '@/Components/Spinner/Spinner';
const EmailBackups = ({ application }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [emailFrequency, setEmailFrequency] = useState(EmailBackupFrequency.Disabled);
    const [emailFrequencyOptions, setEmailFrequencyOptions] = useState([]);
    const [isFailedBackupEmailMuted, setIsFailedBackupEmailMuted] = useState(true);
    const hasAccount = application.hasAccount();
    const loadEmailFrequencySetting = useCallback(async () => {
        if (!application.sessions.getUser()) {
            return;
        }
        setIsLoading(true);
        try {
            const userSettings = await application.settings.listSettings();
            setEmailFrequency(userSettings.getSettingValue(SettingName.create(SettingName.NAMES.EmailBackupFrequency).getValue(), EmailBackupFrequency.Disabled));
            setIsFailedBackupEmailMuted(convertStringifiedBooleanToBoolean(userSettings.getSettingValue(SettingName.create(SettingName.NAMES.MuteFailedBackupsEmails).getValue(), MuteFailedBackupsEmailsOption.NotMuted)));
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [application]);
    useEffect(() => {
        const frequencyOptions = [];
        for (const frequency in EmailBackupFrequency) {
            const frequencyValue = EmailBackupFrequency[frequency];
            frequencyOptions.push({
                value: frequencyValue,
                label: application.settings.getEmailBackupFrequencyOptionLabel(frequencyValue),
            });
        }
        setEmailFrequencyOptions(frequencyOptions);
        loadEmailFrequencySetting().catch(console.error);
    }, [application, loadEmailFrequencySetting]);
    const updateSetting = async (settingName, payload) => {
        try {
            await application.settings.updateSetting(settingName, payload, false);
            return true;
        }
        catch (e) {
            application.alerts.alert(STRING_FAILED_TO_UPDATE_USER_SETTING).catch(console.error);
            return false;
        }
    };
    const updateEmailFrequency = async (frequency) => {
        const previousFrequency = emailFrequency;
        setEmailFrequency(frequency);
        const updateResult = await updateSetting(SettingName.create(SettingName.NAMES.EmailBackupFrequency).getValue(), frequency);
        if (!updateResult) {
            setEmailFrequency(previousFrequency);
        }
    };
    const toggleMuteFailedBackupEmails = async () => {
        const previousValue = isFailedBackupEmailMuted;
        setIsFailedBackupEmailMuted(!isFailedBackupEmailMuted);
        const updateResult = await updateSetting(SettingName.create(SettingName.NAMES.MuteFailedBackupsEmails).getValue(), `${!isFailedBackupEmailMuted}`);
        if (!updateResult) {
            setIsFailedBackupEmailMuted(previousValue);
        }
    };
    const handleEmailFrequencyChange = (item) => {
        updateEmailFrequency(item).catch(console.error);
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Email backups" }), !isDesktopApplication() && (_jsx(Text, { className: "mb-3", children: "Receive daily encrypted email backups of all your notes directly in your email inbox." })), _jsxs("div", { className: `${!hasAccount ? 'pointer-events-none cursor-default opacity-50' : ''}`, children: [_jsx(Subtitle, { children: "Frequency" }), _jsx(Text, { children: "How often to receive backups." }), _jsx("div", { className: "mt-2", children: isLoading ? (_jsx(Spinner, { className: "h-5 w-5 flex-shrink-0" })) : (_jsx(Dropdown, { label: "Select email frequency", items: emailFrequencyOptions, value: emailFrequency, onChange: handleEmailFrequencyChange })) }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsx(Subtitle, { children: "Email preferences" }), _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsx("div", { className: "flex flex-col", children: _jsx(Text, { children: "Receive a notification email if an email backup fails." }) }), isLoading ? (_jsx(Spinner, { className: "h-5 w-5 flex-shrink-0" })) : (_jsx(Switch, { onChange: toggleMuteFailedBackupEmails, checked: !isFailedBackupEmailMuted }))] })] })] }) }));
};
export default observer(EmailBackups);
