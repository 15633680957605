import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { Text } from '@/Components/Preferences/PreferencesComponents/Content';
import { useApplication } from '@/Components/ApplicationProvider';
const U2FDescription = () => {
    const application = useApplication();
    if (application.sessions.getUser() === undefined) {
        return _jsx(Text, { children: "Sign in or register for an account to configure hardware security keys." });
    }
    return (_jsxs("div", { children: [_jsx(Text, { children: "Authenticate with a hardware security key such as YubiKey." }), !application.isFullU2FClient && (_jsx(Text, { className: "italic", children: "Please visit the web app in order to add a hardware security key." }))] }));
};
export default observer(U2FDescription);
