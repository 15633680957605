"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefKey = void 0;
var PrefKey;
(function (PrefKey) {
    PrefKey["TagsPanelWidth"] = "tagsPanelWidth";
    PrefKey["NotesPanelWidth"] = "notesPanelWidth";
    PrefKey["EditorWidth"] = "editorWidth";
    PrefKey["EditorLeft"] = "editorLeft";
    PrefKey["EditorMonospaceEnabled"] = "monospaceFont";
    PrefKey["EditorSpellcheck"] = "spellcheck";
    PrefKey["EditorResizersEnabled"] = "marginResizersEnabled";
    PrefKey["EditorLineHeight"] = "editorLineHeight";
    PrefKey["EditorLineWidth"] = "editorLineWidth";
    PrefKey["EditorFontSize"] = "editorFontSize";
    PrefKey["SortNotesBy"] = "sortBy";
    PrefKey["SortNotesReverse"] = "sortReverse";
    PrefKey["NotesShowArchived"] = "showArchived";
    PrefKey["NotesShowTrashed"] = "showTrashed";
    PrefKey["NotesHideProtected"] = "hideProtected";
    PrefKey["NotesHidePinned"] = "hidePinned";
    PrefKey["NotesHideNotePreview"] = "hideNotePreview";
    PrefKey["NotesHideDate"] = "hideDate";
    PrefKey["NotesHideTags"] = "hideTags";
    PrefKey["NotesHideEditorIcon"] = "hideEditorIcon";
    PrefKey["UseSystemColorScheme"] = "useSystemColorScheme";
    PrefKey["UseTranslucentUI"] = "useTranslucentUI";
    PrefKey["AutoLightThemeIdentifier"] = "autoLightThemeIdentifier";
    PrefKey["AutoDarkThemeIdentifier"] = "autoDarkThemeIdentifier";
    PrefKey["NoteAddToParentFolders"] = "noteAddToParentFolders";
    PrefKey["NewNoteTitleFormat"] = "newNoteTitleFormat";
    PrefKey["CustomNoteTitleFormat"] = "customNoteTitleFormat";
    PrefKey["UpdateSavingStatusIndicator"] = "updateSavingStatusIndicator";
    PrefKey["DefaultEditorIdentifier"] = "defaultEditorIdentifier";
    PrefKey["MomentsDefaultTagUuid"] = "momentsDefaultTagUuid";
    PrefKey["ClipperDefaultTagUuid"] = "clipperDefaultTagUuid";
    PrefKey["SystemViewPreferences"] = "systemViewPreferences";
    PrefKey["SuperNoteExportFormat"] = "superNoteExportFormat";
    PrefKey["SuperNoteExportEmbedBehavior"] = "superNoteExportEmbedBehavior";
    PrefKey["SuperNoteExportUseMDFrontmatter"] = "superNoteExportUseMDFrontmatter";
    PrefKey["SuperNoteExportPDFPageSize"] = "superNoteExportPDFPageSize";
    PrefKey["AuthenticatorNames"] = "authenticatorNames";
    PrefKey["PaneGesturesEnabled"] = "paneGesturesEnabled";
    PrefKey["ComponentPreferences"] = "componentPreferences";
    PrefKey["ActiveThemes"] = "activeThemes";
    PrefKey["ActiveComponents"] = "activeComponents";
    PrefKey["AlwaysShowSuperToolbar"] = "alwaysShowSuperToolbar";
    PrefKey["AddImportsToTag"] = "addImportsToTag";
    PrefKey["AlwaysCreateNewTagForImports"] = "alwaysCreateNewTagForImports";
    PrefKey["ExistingTagForImports"] = "existingTagForImports";
})(PrefKey || (exports.PrefKey = PrefKey = {}));
