import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractComponent } from '@/Components/Abstract/PureComponent';
import ChangeEditorButton from '@/Components/ChangeEditor/ChangeEditorButton';
import IframeFeatureView from '@/Components/ComponentView/IframeFeatureView';
import NotesOptionsPanel from '@/Components/NotesOptions/NotesOptionsPanel';
import PinNoteButton from '@/Components/PinNoteButton/PinNoteButton';
import ProtectedItemOverlay from '@/Components/ProtectedItemOverlay/ProtectedItemOverlay';
import { ElementIds } from '@/Constants/ElementIDs';
import { StringDeleteNote, STRING_DELETE_LOCKED_ATTEMPT, STRING_DELETE_PLACEHOLDER_ATTEMPT } from '@/Constants/Strings';
import { log, LoggingDomain } from '@/Logging';
import { debounce, isDesktopApplication, isMobileScreen } from '@/Utils';
import { classNames, compareArrayReferences, pluralize } from '@standardnotes/utils';
import { ApplicationEvent, ComponentArea, UIFeature, ContentType, isUIFeatureAnIframeFeature, isPayloadSourceRetrieved, NoteType, PayloadEmitSource, PrefDefaults, PrefKey, ProposedSecondsToDeferUILevelSessionExpirationDuringActiveInteraction, VaultUserServiceEvent, } from '@standardnotes/snjs';
import { confirmDialog, DELETE_NOTE_KEYBOARD_COMMAND, KeyboardKey } from '@standardnotes/ui-services';
import { createRef } from 'react';
import { SuperEditor } from '../SuperEditor/SuperEditor';
import IndicatorCircle from '../IndicatorCircle/IndicatorCircle';
import LinkedItemBubblesContainer from '../LinkedItems/LinkedItemBubblesContainer';
import LinkedItemsButton from '../LinkedItems/LinkedItemsButton';
import MobileItemsListButton from '../NoteGroupView/MobileItemsListButton';
import EditingDisabledBanner from './EditingDisabledBanner';
import { reloadFont } from './FontFunctions';
import NoteViewFileDropTarget from './NoteViewFileDropTarget';
import { transactionForAssociateComponentWithCurrentNote, transactionForDisassociateComponentWithCurrentNote, } from './TransactionFunctions';
import { SuperEditorContentId } from '../SuperEditor/Constants';
import { PlainEditor } from './PlainEditor/PlainEditor';
import { EditorMargins, EditorMaxWidths } from '../EditorWidthSelectionModal/EditorWidths';
import NoteStatusIndicator from './NoteStatusIndicator';
import CollaborationInfoHUD from './CollaborationInfoHUD';
import Button from '../Button/Button';
import ModalOverlay from '../Modal/ModalOverlay';
import NoteConflictResolutionModal from './NoteConflictResolutionModal/NoteConflictResolutionModal';
import Icon from '../Icon/Icon';
function sortAlphabetically(array) {
    return array.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
}
class NoteView extends AbstractComponent {
    constructor(props) {
        super(props, props.application);
        this.protectionTimeoutId = null;
        this.setPlainEditorRef = (ref) => {
            this.plainEditorRef = ref || undefined;
        };
        this.authorizeAndDismissProtectedWarning = async () => {
            let showNoteContents = true;
            if (this.application.hasProtectionSources()) {
                showNoteContents = await this.application.authorizeNoteAccess(this.note);
            }
            if (!showNoteContents) {
                return;
            }
            this.setShowProtectedOverlay(false);
            this.focusTitle();
        };
        this.editorComponentViewerRequestsReload = async (viewer, force) => {
            if (this.state.editorComponentViewerDidAlreadyReload && !force) {
                return;
            }
            const component = viewer.getComponentOrFeatureItem();
            this.application.componentManager.destroyComponentViewer(viewer);
            this.setState({
                editorComponentViewer: undefined,
                editorComponentViewerDidAlreadyReload: true,
            }, () => {
                this.setState({
                    editorComponentViewer: this.createComponentViewer(component),
                    editorStateDidLoad: true,
                });
            });
        };
        this.onTitleEnter = ({ key, currentTarget }) => {
            var _a;
            if (key !== KeyboardKey.Enter) {
                return;
            }
            currentTarget.blur();
            (_a = this.plainEditorRef) === null || _a === void 0 ? void 0 : _a.focus();
        };
        this.onTitleChange = ({ currentTarget }) => {
            log(LoggingDomain.NoteView, 'Performing save after title change');
            const title = currentTarget.value;
            this.setState({
                editorTitle: title,
            });
            this.controller
                .saveAndAwaitLocalPropagation({
                title: title,
                isUserModified: true,
                dontGeneratePreviews: true,
            })
                .catch(console.error);
        };
        this.onPanelResizeFinish = async (width, left, isMaxWidth) => {
            if (isMaxWidth) {
                await this.application.setPreference(PrefKey.EditorWidth, null);
            }
            else {
                if (width !== undefined && width !== null) {
                    await this.application.setPreference(PrefKey.EditorWidth, width);
                }
            }
            if (left !== undefined && left !== null) {
                await this.application.setPreference(PrefKey.EditorLeft, left);
            }
            this.application.sync.sync().catch(console.error);
        };
        this.stackComponentExpanded = (component) => {
            return !!this.state.stackComponentViewers.find((viewer) => viewer.componentUniqueIdentifier.value === component.uuid);
        };
        this.toggleStackComponent = async (component) => {
            if (!component.isExplicitlyEnabledForItem(this.note.uuid)) {
                await this.application.mutator.runTransactionalMutation(transactionForAssociateComponentWithCurrentNote(component, this.note));
            }
            else {
                await this.application.mutator.runTransactionalMutation(transactionForDisassociateComponentWithCurrentNote(component, this.note));
            }
            this.application.sync.sync().catch(console.error);
        };
        this.ensureNoteIsInsertedBeforeUIAction = async () => {
            if (this.controller.isTemplateNote) {
                await this.controller.insertTemplatedNote();
            }
        };
        this.onPlainFocus = () => {
            this.setState({ plainEditorFocused: true });
        };
        this.onPlainBlur = (event) => {
            var _a;
            if (((_a = event.relatedTarget) === null || _a === void 0 ? void 0 : _a.id) === ElementIds.NoteOptionsButton) {
                return;
            }
            this.setState({ plainEditorFocused: false });
        };
        this.toggleConflictResolutionModal = () => {
            this.setState((state) => ({
                showConflictResolutionModal: !state.showConflictResolutionModal,
            }));
        };
        this.triggerSyncOnAction = () => {
            this.controller.syncNow();
        };
        this.controller = props.controller;
        this.onEditorComponentLoad = () => {
            var _a;
            if (!this.controller || this.controller.dealloced) {
                return;
            }
            (_a = this.application.desktopManager) === null || _a === void 0 ? void 0 : _a.redoSearch();
        };
        this.debounceReloadEditorComponent = debounce(this.debounceReloadEditorComponent.bind(this), 25);
        const itemVault = this.application.vaults.getItemVault(this.controller.item);
        this.state = {
            availableStackComponents: [],
            editorStateDidLoad: false,
            editorTitle: '',
            editorLineWidth: PrefDefaults[PrefKey.EditorLineWidth],
            isDesktop: isDesktopApplication(),
            noteStatus: undefined,
            noteLocked: this.controller.item.locked,
            readonly: itemVault ? this.application.vaultUsers.isCurrentUserReadonlyVaultMember(itemVault) : undefined,
            showProtectedWarning: false,
            spellcheck: true,
            stackComponentViewers: [],
            syncTakingTooLong: false,
            editorFeatureIdentifier: this.controller.item.editorIdentifier,
            noteType: this.controller.item.noteType,
            conflictedNotes: [],
            showConflictResolutionModal: false,
        };
        this.noteViewElementRef = createRef();
        this.editorContentRef = createRef();
    }
    deinit() {
        var _a, _b, _c, _d, _e;
        super.deinit();
        this.controller = undefined;
        (_a = this.removeNoteStreamObserver) === null || _a === void 0 ? void 0 : _a.call(this);
        this.removeNoteStreamObserver = undefined;
        (_b = this.removeInnerNoteObserver) === null || _b === void 0 ? void 0 : _b.call(this);
        this.removeInnerNoteObserver = undefined;
        (_c = this.removeComponentManagerObserver) === null || _c === void 0 ? void 0 : _c.call(this);
        this.removeComponentManagerObserver = undefined;
        (_d = this.removeTrashKeyObserver) === null || _d === void 0 ? void 0 : _d.call(this);
        this.removeTrashKeyObserver = undefined;
        (_e = this.removeVaultUsersEventHandler) === null || _e === void 0 ? void 0 : _e.call(this);
        this.removeVaultUsersEventHandler = undefined;
        this.clearNoteProtectionInactivityTimer();
        this.ensureNoteIsInsertedBeforeUIAction = undefined;
        this.onEditorComponentLoad = undefined;
        this.onPanelResizeFinish = undefined;
        this.authorizeAndDismissProtectedWarning = undefined;
        this.editorComponentViewerRequestsReload = undefined;
        this.onTitleEnter = undefined;
        this.onTitleChange = undefined;
        this.onPanelResizeFinish = undefined;
        this.stackComponentExpanded = undefined;
        this.toggleStackComponent = undefined;
        this.debounceReloadEditorComponent = undefined;
        this.editorContentRef = undefined;
        this.plainEditorRef = undefined;
    }
    getState() {
        return this.state;
    }
    get note() {
        return this.controller.item;
    }
    shouldComponentUpdate(_nextProps, nextState) {
        for (const key of Object.keys(nextState)) {
            const prevValue = this.state[key];
            const nextValue = nextState[key];
            if (Array.isArray(prevValue) && Array.isArray(nextValue)) {
                const areEqual = compareArrayReferences(prevValue, nextValue);
                if (!areEqual) {
                    log(LoggingDomain.NoteView, 'Rendering due to array state change', key, prevValue, nextValue);
                    return true;
                }
                continue;
            }
            if (prevValue !== nextValue) {
                log(LoggingDomain.NoteView, 'Rendering due to state change', key, prevValue, nextValue);
                return true;
            }
        }
        return false;
    }
    componentDidMount() {
        super.componentDidMount();
        this.removeVaultUsersEventHandler = this.application.vaultUsers.addEventObserver((event, data) => {
            var _a;
            if (event === VaultUserServiceEvent.InvalidatedUserCacheForVault) {
                const vault = this.application.vaults.getItemVault(this.note);
                if (data !== ((_a = vault === null || vault === void 0 ? void 0 : vault.sharing) === null || _a === void 0 ? void 0 : _a.sharedVaultUuid)) {
                    return;
                }
                this.setState({
                    readonly: vault ? this.application.vaultUsers.isCurrentUserReadonlyVaultMember(vault) : undefined,
                });
            }
        });
        this.registerKeyboardShortcuts();
        this.removeInnerNoteObserver = this.controller.addNoteInnerValueChangeObserver((note, source) => {
            this.onNoteInnerChange(note, source);
        });
        this.autorun(() => {
            const syncStatus = this.controller.syncStatus;
            const isFocusModeEnabled = this.application.paneController.focusModeEnabled;
            const didFocusModeChange = this.state.focusModeEnabled !== isFocusModeEnabled;
            this.setState({
                showProtectedWarning: this.application.notesController.showProtectedWarning,
                noteStatus: syncStatus,
                saveError: (syncStatus === null || syncStatus === void 0 ? void 0 : syncStatus.type) === 'error',
                syncTakingTooLong: false,
                focusModeEnabled: isFocusModeEnabled,
            });
            if (!isFocusModeEnabled && didFocusModeChange) {
                this.controller.syncOnlyIfLargeNote();
            }
        });
        this.reloadEditorComponent().catch(console.error);
        this.reloadStackComponents().catch(console.error);
        const showProtectedWarning = this.note.protected &&
            (!this.application.hasProtectionSources() || !this.application.protections.hasUnprotectedAccessSession());
        this.setShowProtectedOverlay(showProtectedWarning);
        this.reloadPreferences().catch(console.error);
        if (this.controller.isTemplateNote) {
            setTimeout(() => {
                var _a;
                if (((_a = this.controller.templateNoteOptions) === null || _a === void 0 ? void 0 : _a.autofocusBehavior) === 'title') {
                    this.focusTitle();
                }
            });
        }
    }
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.showProtectedWarning != undefined &&
            prevState.showProtectedWarning !== this.state.showProtectedWarning) {
            this.reloadEditorComponent().catch(console.error);
        }
    }
    onNoteInnerChange(note, source) {
        log(LoggingDomain.NoteView, 'On inner note change', PayloadEmitSource[source]);
        if (note.uuid !== this.note.uuid) {
            throw Error('Editor received changes for non-current note');
        }
        let title = this.state.editorTitle;
        if (isPayloadSourceRetrieved(source)) {
            title = note.title;
        }
        if (!this.state.editorTitle) {
            title = note.title;
        }
        if (title !== this.state.editorTitle) {
            this.setState({
                editorTitle: title,
            });
        }
        if (note.last_edited_by_uuid !== this.state.noteLastEditedByUuid) {
            this.setState({
                noteLastEditedByUuid: note.last_edited_by_uuid,
            });
        }
        if (note.locked !== this.state.noteLocked) {
            this.setState({
                noteLocked: note.locked,
            });
        }
        if (note.editorIdentifier !== this.state.editorFeatureIdentifier || note.noteType !== this.state.noteType) {
            this.setState({
                editorFeatureIdentifier: note.editorIdentifier,
                noteType: note.noteType,
                editorTitle: note.title,
            });
            void this.reloadEditorComponent();
        }
        this.reloadSpellcheck().catch(console.error);
        this.reloadLineWidth();
        const isTemplateNoteInsertedToBeInteractableWithEditor = source === PayloadEmitSource.LocalInserted && note.dirty;
        if (isTemplateNoteInsertedToBeInteractableWithEditor) {
            return;
        }
        if (note.lastSyncBegan || note.dirty) {
            const currentStatus = this.controller.syncStatus;
            const isWaitingToSyncLargeNote = (currentStatus === null || currentStatus === void 0 ? void 0 : currentStatus.type) === 'waiting';
            if (note.lastSyncEnd) {
                const hasStartedNewSync = note.lastSyncBegan && note.lastSyncBegan.getTime() > note.lastSyncEnd.getTime();
                const shouldShowSavedStatus = note.lastSyncBegan && note.lastSyncEnd.getTime() > note.lastSyncBegan.getTime();
                if (hasStartedNewSync) {
                    this.controller.showSavingStatus();
                }
                else if (this.state.noteStatus && shouldShowSavedStatus && !isWaitingToSyncLargeNote) {
                    this.controller.showAllChangesSavedStatus();
                }
            }
            else if (note.lastSyncBegan) {
                this.controller.showSavingStatus();
            }
        }
    }
    componentWillUnmount() {
        var _a;
        if (this.state.editorComponentViewer) {
            (_a = this.application.componentManager) === null || _a === void 0 ? void 0 : _a.destroyComponentViewer(this.state.editorComponentViewer);
        }
        super.componentWillUnmount();
    }
    async onAppLaunch() {
        await super.onAppLaunch();
        this.streamItems();
    }
    async onAppEvent(eventName) {
        var _a;
        if ((_a = this.controller) === null || _a === void 0 ? void 0 : _a.dealloced) {
            return;
        }
        switch (eventName) {
            case ApplicationEvent.PreferencesChanged:
                void this.reloadPreferences();
                void this.reloadStackComponents();
                break;
            case ApplicationEvent.HighLatencySync:
                this.setState({ syncTakingTooLong: true });
                break;
            case ApplicationEvent.CompletedFullSync: {
                this.setState({ syncTakingTooLong: false });
                const isInErrorState = this.state.saveError;
                /** if we're still dirty, don't change status, a sync is likely upcoming. */
                if (!this.note.dirty && isInErrorState) {
                    this.controller.showAllChangesSavedStatus();
                }
                break;
            }
            case ApplicationEvent.FailedSync:
                /**
                 * Only show error status in editor if the note is dirty.
                 * Otherwise, it means the originating sync came from somewhere else
                 * and we don't want to display an error here.
                 */
                if (this.note.dirty) {
                    this.controller.showErrorSyncStatus();
                }
                break;
            case ApplicationEvent.LocalDatabaseWriteError:
                this.controller.showErrorSyncStatus({
                    type: 'error',
                    message: 'Offline Saving Issue',
                    description: 'Changes not saved',
                });
                break;
            case ApplicationEvent.UnprotectedSessionBegan: {
                this.setShowProtectedOverlay(false);
                break;
            }
            case ApplicationEvent.UnprotectedSessionExpired: {
                if (this.note.protected) {
                    this.hideProtectedNoteIfInactive();
                }
                break;
            }
        }
    }
    getSecondsElapsedSinceLastEdit() {
        return (Date.now() - this.note.userModifiedDate.getTime()) / 1000;
    }
    hideProtectedNoteIfInactive() {
        const secondsElapsedSinceLastEdit = this.getSecondsElapsedSinceLastEdit();
        if (secondsElapsedSinceLastEdit >= ProposedSecondsToDeferUILevelSessionExpirationDuringActiveInteraction) {
            this.setShowProtectedOverlay(true);
        }
        else {
            const secondsUntilTheNextCheck = ProposedSecondsToDeferUILevelSessionExpirationDuringActiveInteraction - secondsElapsedSinceLastEdit;
            this.startNoteProtectionInactivityTimer(secondsUntilTheNextCheck);
        }
    }
    startNoteProtectionInactivityTimer(timerDurationInSeconds) {
        this.clearNoteProtectionInactivityTimer();
        this.protectionTimeoutId = setTimeout(() => {
            this.hideProtectedNoteIfInactive();
        }, timerDurationInSeconds * 1000);
    }
    clearNoteProtectionInactivityTimer() {
        if (this.protectionTimeoutId) {
            clearTimeout(this.protectionTimeoutId);
        }
    }
    streamItems() {
        this.removeNoteStreamObserver = this.application.items.streamItems(ContentType.TYPES.Note, async () => {
            if (!this.note) {
                return;
            }
            this.setState({
                conflictedNotes: this.application.items.conflictsOf(this.note.uuid),
            });
        });
    }
    createComponentViewer(component) {
        if (!component) {
            throw Error('Cannot create component viewer for undefined component');
        }
        const viewer = this.application.componentManager.createComponentViewer(component, { uuid: this.note.uuid });
        return viewer;
    }
    /**
     * Calling reloadEditorComponent successively without waiting for state to settle
     * can result in componentViewers being dealloced twice
     */
    debounceReloadEditorComponent() {
        this.reloadEditorComponent().catch(console.error);
    }
    destroyCurrentEditorComponent() {
        const currentComponentViewer = this.state.editorComponentViewer;
        if (currentComponentViewer) {
            this.application.componentManager.destroyComponentViewer(currentComponentViewer);
            this.setState({
                editorComponentViewer: undefined,
            });
        }
    }
    async reloadEditorComponent() {
        log(LoggingDomain.NoteView, 'Reload editor component');
        if (this.state.showProtectedWarning) {
            this.destroyCurrentEditorComponent();
            return;
        }
        const newUIFeature = this.application.componentManager.editorForNote(this.note);
        /** Component editors cannot interact with template notes so the note must be inserted */
        if (isUIFeatureAnIframeFeature(newUIFeature) && this.controller.isTemplateNote) {
            await this.controller.insertTemplatedNote();
        }
        const currentComponentViewer = this.state.editorComponentViewer;
        if (currentComponentViewer) {
            const needsDestroy = currentComponentViewer.componentUniqueIdentifier !== newUIFeature.uniqueIdentifier;
            if (needsDestroy) {
                this.destroyCurrentEditorComponent();
            }
        }
        if (isUIFeatureAnIframeFeature(newUIFeature)) {
            this.setState({
                editorComponentViewer: this.createComponentViewer(newUIFeature),
                editorStateDidLoad: true,
            });
        }
        else {
            reloadFont(this.state.monospaceFont);
            this.setState({
                editorStateDidLoad: true,
            });
        }
    }
    hasAvailableExtensions() {
        return this.application.actions.extensionsInContextOfItem(this.note).length > 0;
    }
    focusTitle() {
        var _a;
        (_a = document.getElementById(ElementIds.NoteTitleEditor)) === null || _a === void 0 ? void 0 : _a.focus();
    }
    setShowProtectedOverlay(show) {
        this.application.notesController.setShowProtectedWarning(show);
    }
    async deleteNote(permanently) {
        if (this.controller.isTemplateNote) {
            this.application.alerts.alert(STRING_DELETE_PLACEHOLDER_ATTEMPT).catch(console.error);
            return;
        }
        if (this.note.locked) {
            this.application.alerts.alert(STRING_DELETE_LOCKED_ATTEMPT).catch(console.error);
            return;
        }
        const title = this.note.title.length ? `'${this.note.title}'` : 'this note';
        const text = StringDeleteNote(title, permanently);
        if (await confirmDialog({
            text,
            confirmButtonStyle: 'danger',
        })) {
            if (permanently) {
                this.performNoteDeletion(this.note);
            }
            else {
                this.controller
                    .saveAndAwaitLocalPropagation({
                    title: this.state.editorTitle,
                    bypassDebouncer: true,
                    dontGeneratePreviews: true,
                    isUserModified: true,
                    customMutate: (mutator) => {
                        mutator.trashed = true;
                    },
                })
                    .catch(console.error);
            }
        }
    }
    performNoteDeletion(note) {
        this.application.mutator
            .deleteItem(note)
            .then(() => this.application.sync.sync())
            .catch(console.error);
    }
    async reloadSpellcheck() {
        const spellcheck = this.application.notesController.getSpellcheckStateForNote(this.note);
        if (spellcheck !== this.state.spellcheck) {
            reloadFont(this.state.monospaceFont);
            this.setState({ spellcheck });
        }
    }
    reloadLineWidth() {
        const editorLineWidth = this.application.notesController.getEditorWidthForNote(this.note);
        this.setState({
            editorLineWidth,
        });
    }
    async reloadPreferences() {
        log(LoggingDomain.NoteView, 'Reload preferences');
        const monospaceFont = this.application.getPreference(PrefKey.EditorMonospaceEnabled, PrefDefaults[PrefKey.EditorMonospaceEnabled]);
        const updateSavingIndicator = this.application.getPreference(PrefKey.UpdateSavingStatusIndicator, PrefDefaults[PrefKey.UpdateSavingStatusIndicator]);
        const paneGestureEnabled = this.application.getPreference(PrefKey.PaneGesturesEnabled, PrefDefaults[PrefKey.PaneGesturesEnabled]);
        await this.reloadSpellcheck();
        this.reloadLineWidth();
        this.setState({
            monospaceFont,
            updateSavingIndicator,
            paneGestureEnabled,
        });
        reloadFont(monospaceFont);
    }
    async reloadStackComponents() {
        log(LoggingDomain.NoteView, 'Reload stack components');
        const enabledComponents = sortAlphabetically(this.application.componentManager
            .thirdPartyComponentsForArea(ComponentArea.EditorStack)
            .filter((component) => this.application.componentManager.isComponentActive(component)));
        const needsNewViewer = enabledComponents.filter((component) => {
            const hasExistingViewer = this.state.stackComponentViewers.find((viewer) => viewer.componentUniqueIdentifier.value === component.uuid);
            return !hasExistingViewer;
        });
        const needsDestroyViewer = this.state.stackComponentViewers.filter((viewer) => {
            const viewerComponentExistsInEnabledComponents = enabledComponents.find((component) => {
                return component.uuid === viewer.componentUniqueIdentifier.value;
            });
            return !viewerComponentExistsInEnabledComponents;
        });
        const newViewers = [];
        for (const component of needsNewViewer) {
            newViewers.push(this.application.componentManager.createComponentViewer(new UIFeature(component), {
                uuid: this.note.uuid,
            }));
        }
        for (const viewer of needsDestroyViewer) {
            this.application.componentManager.destroyComponentViewer(viewer);
        }
        this.setState({
            availableStackComponents: enabledComponents,
            stackComponentViewers: newViewers,
        });
    }
    registerKeyboardShortcuts() {
        this.removeTrashKeyObserver = this.application.keyboardService.addCommandHandler({
            command: DELETE_NOTE_KEYBOARD_COMMAND,
            notTags: ['INPUT', 'TEXTAREA'],
            notElementIds: [SuperEditorContentId],
            onKeyDown: () => {
                this.deleteNote(false).catch(console.error);
            },
        });
    }
    render() {
        if (this.controller.dealloced) {
            return null;
        }
        if (this.state.showProtectedWarning || !this.application.isAuthorizedToRenderItem(this.note)) {
            return (_jsx(ProtectedItemOverlay, { showAccountMenu: () => this.application.showAccountMenu(), hasProtectionSources: this.application.hasProtectionSources(), onViewItem: this.authorizeAndDismissProtectedWarning, itemType: 'note' }));
        }
        const renderHeaderOptions = isMobileScreen() ? !this.state.plainEditorFocused : true;
        const editorMode = this.note.noteType === NoteType.Super
            ? 'super'
            : this.state.editorStateDidLoad && !this.state.editorComponentViewer
                ? 'plain'
                : this.state.editorComponentViewer
                    ? 'component'
                    : 'plain';
        const shouldShowConflictsButton = this.state.conflictedNotes.length > 0 && !this.state.readonly;
        return (_jsxs("div", { "aria-label": "Note", className: "section editor sn-component h-full md:max-h-full", ref: this.noteViewElementRef, children: [this.note && (_jsx(NoteViewFileDropTarget, { note: this.note, linkingController: this.application.linkingController, filesController: this.application.filesController, noteViewElement: this.noteViewElementRef.current })), this.state.readonly && (_jsxs("div", { className: "bg-warning-faded flex items-center px-3.5 py-2 text-sm text-accessory-tint-3", children: [_jsx(Icon, { type: "pencil-off", className: "mr-3" }), "You don't have permission to edit this note"] })), this.state.noteLocked && (_jsx(EditingDisabledBanner, { onClick: () => this.application.notesController.setLockSelectedNotes(!this.state.noteLocked), noteLocked: this.state.noteLocked })), this.note && (_jsxs("div", { id: "editor-title-bar", className: "content-title-bar section-title-bar z-editor-title-bar w-full bg-default pt-4", children: [_jsxs("div", { className: classNames('mb-2 flex justify-between md:mb-0 md:flex-nowrap md:gap-4 xl:items-center', shouldShowConflictsButton ? 'items-center' : 'items-start', !renderHeaderOptions ? 'flex-nowrap gap-4' : 'flex-wrap gap-2 '), children: [_jsxs("div", { className: classNames(this.state.noteLocked && 'locked', 'flex flex-grow items-center'), children: [_jsx(MobileItemsListButton, {}), _jsx("div", { className: "title flex-grow overflow-auto", children: _jsx("input", { className: "input text-lg", disabled: this.state.noteLocked || this.state.readonly, id: ElementIds.NoteTitleEditor, onChange: this.onTitleChange, onFocus: (event) => {
                                                    event.target.select();
                                                }, onKeyUp: this.onTitleEnter, spellCheck: false, value: this.state.editorTitle, autoComplete: "off" }) }), _jsx(NoteStatusIndicator, { note: this.note, status: this.state.noteStatus, syncTakingTooLong: this.state.syncTakingTooLong, updateSavingIndicator: this.state.updateSavingIndicator })] }), shouldShowConflictsButton && (_jsxs(Button, { id: ElementIds.ConflictResolutionButton, className: "flex items-center", primary: true, colorStyle: "warning", small: true, onClick: this.toggleConflictResolutionModal, children: [_jsx(Icon, { type: "merge", size: "small", className: "mr-2" }), this.state.conflictedNotes.length, ' ', pluralize(this.state.conflictedNotes.length, 'conflict', 'conflicts')] })), _jsxs("div", { className: "note-view-options-buttons flex items-center gap-3", children: [!this.state.readonly && renderHeaderOptions && (_jsxs(_Fragment, { children: [_jsx(LinkedItemsButton, { linkingController: this.application.linkingController, onClick: this.triggerSyncOnAction, onClickPreprocessing: this.ensureNoteIsInsertedBeforeUIAction }), _jsx(ChangeEditorButton, { noteViewController: this.controller, onClick: this.triggerSyncOnAction, onClickPreprocessing: this.ensureNoteIsInsertedBeforeUIAction }), _jsx(PinNoteButton, { notesController: this.application.notesController, onClickPreprocessing: this.ensureNoteIsInsertedBeforeUIAction })] })), _jsx(NotesOptionsPanel, { notesController: this.application.notesController, onClick: this.triggerSyncOnAction, onClickPreprocessing: this.ensureNoteIsInsertedBeforeUIAction, onButtonBlur: () => {
                                                this.setState({
                                                    plainEditorFocused: false,
                                                });
                                            } })] })] }), _jsx("div", { className: "mb-1 mt-2.5 md:hidden", children: _jsx(CollaborationInfoHUD, { item: this.note }) }), _jsx("div", { className: "hidden md:block", children: _jsx(LinkedItemBubblesContainer, { item: this.note, linkingController: this.application.linkingController, readonly: this.state.readonly }) })] })), _jsxs("div", { id: ElementIds.EditorContent, className: classNames(ElementIds.EditorContent, 'z-editor-content overflow-auto [&>*]:mx-[var(--editor-margin)] [&>*]:max-w-[var(--editor-max-width)]'), style: {
                        '--editor-margin': EditorMargins[this.state.editorLineWidth],
                        '--editor-max-width': EditorMaxWidths[this.state.editorLineWidth],
                    }, ref: this.editorContentRef, children: [editorMode === 'component' && this.state.editorComponentViewer && (_jsxs("div", { className: "component-view relative flex-grow", children: [this.state.paneGestureEnabled && _jsx("div", { className: "absolute left-0 top-0 h-full w-[20px] md:hidden" }), _jsx(IframeFeatureView, { componentViewer: this.state.editorComponentViewer, onLoad: this.onEditorComponentLoad, requestReload: this.editorComponentViewerRequestsReload, readonly: this.state.readonly }, this.state.editorComponentViewer.identifier)] })), editorMode === 'plain' && (_jsx(PlainEditor, { application: this.application, spellcheck: this.state.spellcheck, ref: this.setPlainEditorRef, controller: this.controller, locked: this.state.noteLocked || !!this.state.readonly, onFocus: this.onPlainFocus, onBlur: this.onPlainBlur })), editorMode === 'super' && (_jsx("div", { className: classNames('blocks-editor w-full flex-grow overflow-hidden'), children: _jsx(SuperEditor, { application: this.application, linkingController: this.application.linkingController, filesController: this.application.filesController, spellcheck: this.state.spellcheck, controller: this.controller, readonly: this.state.readonly }, this.note.uuid) }))] }), _jsxs("div", { id: "editor-pane-component-stack", children: [this.state.availableStackComponents.length > 0 && (_jsx("div", { id: "component-stack-menu-bar", className: "flex h-6 w-full items-center justify-between border-t border-solid border-border bg-contrast px-2 py-0 text-text", children: _jsx("div", { className: "flex h-full", children: this.state.availableStackComponents.map((component) => {
                                    const active = this.application.componentManager.isComponentActive(component);
                                    return (_jsxs("div", { onClick: () => {
                                            this.toggleStackComponent(component).catch(console.error);
                                        }, className: "flex flex-grow cursor-pointer items-center justify-center [&:not(:first-child)]:ml-3", children: [_jsxs("div", { className: "flex h-full items-center [&:not(:first-child)]:ml-2", children: [this.stackComponentExpanded(component) && active && _jsx(IndicatorCircle, { style: "info" }), !this.stackComponentExpanded(component) && _jsx(IndicatorCircle, { style: "neutral" })] }), _jsx("div", { className: "flex h-full items-center [&:not(:first-child)]:ml-2", children: _jsx("div", { className: "whitespace-nowrap text-xs font-bold", children: component.name }) })] }, component.uuid));
                                }) }) })), _jsx("div", { className: "sn-component", children: this.state.stackComponentViewers.map((viewer) => {
                                return (_jsx("div", { className: "component-view component-stack-item", children: _jsx(IframeFeatureView, { componentViewer: viewer }, viewer.identifier) }, viewer.identifier));
                            }) })] }), _jsx(ModalOverlay, { isOpen: this.state.showConflictResolutionModal, close: this.toggleConflictResolutionModal, className: "md:h-full md:w-[70vw]", children: _jsx(NoteConflictResolutionModal, { currentNote: this.note, conflictedNotes: this.state.conflictedNotes, close: this.toggleConflictResolutionModal }) })] }));
    }
}
export default NoteView;
