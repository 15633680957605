import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NativeFeatureIdentifier, FeatureStatus, MuteMarketingEmailsOption, MuteSignInEmailsOption, SettingName, } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Switch from '@/Components/Switch/Switch';
import { Subtitle, Text, Title } from '@/Components/Preferences/PreferencesComponents/Content';
import { STRING_FAILED_TO_UPDATE_USER_SETTING } from '@/Constants/Strings';
import PreferencesGroup from '@/Components/Preferences/PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '@/Components/Preferences/PreferencesComponents/PreferencesSegment';
import Spinner from '@/Components/Spinner/Spinner';
import NoProSubscription from '../NoProSubscription';
const Email = ({ application }) => {
    const [signInEmailsMutedValue, setSignInEmailsMutedValue] = useState(MuteSignInEmailsOption.NotMuted);
    const [marketingEmailsMutedValue, setMarketingEmailsMutedValue] = useState(MuteMarketingEmailsOption.NotMuted);
    const [isLoading, setIsLoading] = useState(true);
    const isMuteSignInEmailsFeatureAvailable = application.features.getFeatureStatus(NativeFeatureIdentifier.create(NativeFeatureIdentifier.TYPES.SignInAlerts).getValue()) === FeatureStatus.Entitled;
    const updateSetting = async (settingName, payload) => {
        try {
            await application.settings.updateSetting(settingName, payload, false);
            return true;
        }
        catch (e) {
            application.alerts.alert(STRING_FAILED_TO_UPDATE_USER_SETTING).catch(console.error);
            return false;
        }
    };
    const loadSettings = useCallback(async () => {
        if (!application.sessions.getUser()) {
            return;
        }
        setIsLoading(true);
        try {
            const userSettings = await application.settings.listSettings();
            setSignInEmailsMutedValue(userSettings.getSettingValue(SettingName.create(SettingName.NAMES.MuteSignInEmails).getValue(), MuteSignInEmailsOption.NotMuted)),
                setMarketingEmailsMutedValue(userSettings.getSettingValue(SettingName.create(SettingName.NAMES.MuteMarketingEmails).getValue(), MuteMarketingEmailsOption.NotMuted));
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [application]);
    useEffect(() => {
        loadSettings().catch(console.error);
    }, [loadSettings]);
    const toggleMuteSignInEmails = async () => {
        const previousValue = signInEmailsMutedValue;
        const newValue = previousValue === MuteSignInEmailsOption.Muted ? MuteSignInEmailsOption.NotMuted : MuteSignInEmailsOption.Muted;
        setSignInEmailsMutedValue(newValue);
        const updateResult = await updateSetting(SettingName.create(SettingName.NAMES.MuteSignInEmails).getValue(), newValue);
        if (!updateResult) {
            setSignInEmailsMutedValue(previousValue);
        }
    };
    const toggleMuteMarketingEmails = async () => {
        const previousValue = marketingEmailsMutedValue;
        const newValue = previousValue === MuteMarketingEmailsOption.Muted
            ? MuteMarketingEmailsOption.NotMuted
            : MuteMarketingEmailsOption.Muted;
        setMarketingEmailsMutedValue(newValue);
        const updateResult = await updateSetting(SettingName.create(SettingName.NAMES.MuteMarketingEmails).getValue(), newValue);
        if (!updateResult) {
            setMarketingEmailsMutedValue(previousValue);
        }
    };
    return (_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Email" }), _jsxs("div", { children: [_jsxs("div", { className: "flex items-start justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Mute sign-in notification emails" }), isMuteSignInEmailsFeatureAvailable ? (_jsx(Text, { children: "Disables email notifications when a new sign-in occurs on your account. (Email notifications are available only to paid subscribers)." })) : (_jsx(NoProSubscription, { application: application, text: _jsxs("span", { children: ["Sign-in notification emails are available only on a", ' ', _jsx("span", { className: "font-bold", children: "subscription" }), " plan. Please upgrade in order to enable sign-in notifications."] }) }))] }), isLoading ? (_jsx(Spinner, { className: "h-5 w-5 flex-shrink-0" })) : (isMuteSignInEmailsFeatureAvailable && (_jsx(Switch, { onChange: toggleMuteSignInEmails, checked: signInEmailsMutedValue === MuteSignInEmailsOption.Muted })))] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { className: "flex items-start justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Mute marketing notification emails" }), _jsx(Text, { children: "Disables email notifications with special deals and promotions." })] }), isLoading ? (_jsx(Spinner, { className: "h-5 w-5 flex-shrink-0" })) : (_jsx(Switch, { onChange: toggleMuteMarketingEmails, checked: marketingEmailsMutedValue === MuteMarketingEmailsOption.Muted }))] })] })] }) }));
};
export default observer(Email);
