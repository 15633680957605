import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dropdown from '@/Components/Dropdown/Dropdown';
import { usePremiumModal } from '@/Hooks/usePremiumModal';
import HorizontalSeparator from '@/Components/Shared/HorizontalSeparator';
import Switch from '@/Components/Switch/Switch';
import { PrefKey, FeatureStatus, naturalSort, PrefDefaults } from '@standardnotes/snjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Subtitle, Title, Text } from '@/Components/Preferences/PreferencesComponents/Content';
import PreferencesPane from '../PreferencesComponents/PreferencesPane';
import PreferencesGroup from '../PreferencesComponents/PreferencesGroup';
import PreferencesSegment from '../PreferencesComponents/PreferencesSegment';
import { PremiumFeatureIconName } from '@/Components/Icon/PremiumFeatureIcon';
import EditorAppearance from './Appearance/EditorAppearance';
import { GetAllThemesUseCase } from '@standardnotes/ui-services';
import usePreference from '@/Hooks/usePreference';
const Appearance = ({ application }) => {
    const premiumModal = usePremiumModal();
    const [themeItems, setThemeItems] = useState([]);
    const [autoLightTheme, setAutoLightTheme] = useState(() => application.getPreference(PrefKey.AutoLightThemeIdentifier, PrefDefaults[PrefKey.AutoLightThemeIdentifier]));
    const [autoDarkTheme, setAutoDarkTheme] = useState(() => application.getPreference(PrefKey.AutoDarkThemeIdentifier, PrefDefaults[PrefKey.AutoDarkThemeIdentifier]));
    const [useDeviceSettings, setUseDeviceSettings] = useState(() => application.getPreference(PrefKey.UseSystemColorScheme, PrefDefaults[PrefKey.UseSystemColorScheme]));
    const useTranslucentUI = usePreference(PrefKey.UseTranslucentUI);
    const toggleTranslucentUI = () => {
        application.setPreference(PrefKey.UseTranslucentUI, !useTranslucentUI).catch(console.error);
    };
    useEffect(() => {
        const usecase = new GetAllThemesUseCase(application.items);
        const { thirdParty, native } = usecase.execute({ excludeLayerable: true });
        const dropdownItems = [];
        dropdownItems.push({
            label: 'Default',
            value: 'Default',
        });
        dropdownItems.push(...native.map((theme) => {
            return {
                label: theme.displayName,
                value: theme.featureIdentifier,
                icon: application.features.getFeatureStatus(theme.uniqueIdentifier) !== FeatureStatus.Entitled
                    ? PremiumFeatureIconName
                    : undefined,
            };
        }));
        dropdownItems.push(...thirdParty.map((theme) => {
            return {
                label: theme.displayName,
                value: theme.featureIdentifier,
            };
        }));
        setThemeItems(naturalSort(dropdownItems, 'label'));
    }, [application]);
    const toggleUseDeviceSettings = () => {
        application.setPreference(PrefKey.UseSystemColorScheme, !useDeviceSettings).catch(console.error);
        if (!application.getPreference(PrefKey.AutoLightThemeIdentifier)) {
            application.setPreference(PrefKey.AutoLightThemeIdentifier, autoLightTheme).catch(console.error);
        }
        if (!application.getPreference(PrefKey.AutoDarkThemeIdentifier)) {
            application.setPreference(PrefKey.AutoDarkThemeIdentifier, autoDarkTheme).catch(console.error);
        }
        setUseDeviceSettings(!useDeviceSettings);
    };
    const changeAutoLightTheme = (value) => {
        const item = themeItems.find((item) => item.value === value);
        if (item && item.icon === PremiumFeatureIconName) {
            premiumModal.activate(`${item.label} theme`);
            return;
        }
        application.setPreference(PrefKey.AutoLightThemeIdentifier, value).catch(console.error);
        setAutoLightTheme(value);
    };
    const changeAutoDarkTheme = (value) => {
        const item = themeItems.find((item) => item.value === value);
        if (item && item.icon === PremiumFeatureIconName) {
            premiumModal.activate(`${item.label} theme`);
            return;
        }
        application.setPreference(PrefKey.AutoDarkThemeIdentifier, value).catch(console.error);
        setAutoDarkTheme(value);
    };
    return (_jsxs(PreferencesPane, { children: [_jsx(PreferencesGroup, { children: _jsxs(PreferencesSegment, { children: [_jsx(Title, { children: "Themes" }), _jsxs("div", { className: "mt-2", children: [_jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Disable translucent UI" }), _jsx(Text, { children: "Use opaque style for UI elements instead of translucency" })] }), _jsx(Switch, { onChange: toggleTranslucentUI, checked: !useTranslucentUI })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { className: "flex justify-between gap-2 md:items-center", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx(Subtitle, { children: "Use system color scheme" }), _jsx(Text, { children: "Automatically change active theme based on your system settings." })] }), _jsx(Switch, { onChange: toggleUseDeviceSettings, checked: useDeviceSettings })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Automatic Light Theme" }), _jsx(Text, { children: "Theme to be used for system light mode:" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the automatic light theme", items: themeItems, value: autoLightTheme, onChange: changeAutoLightTheme, disabled: !useDeviceSettings }) })] }), _jsx(HorizontalSeparator, { classes: "my-4" }), _jsxs("div", { children: [_jsx(Subtitle, { children: "Automatic Dark Theme" }), _jsx(Text, { children: "Theme to be used for system dark mode:" }), _jsx("div", { className: "mt-2", children: _jsx(Dropdown, { label: "Select the automatic dark theme", items: themeItems, value: autoDarkTheme, onChange: changeAutoDarkTheme, disabled: !useDeviceSettings }) })] })] })] }) }), _jsx(EditorAppearance, { application: application })] }));
};
export default observer(Appearance);
