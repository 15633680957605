import { useApplication } from '@/Components/ApplicationProvider';
import { ApplicationEvent, PrefDefaults } from '@standardnotes/snjs';
import { useEffect, useState } from 'react';
export default function usePreference(preference) {
    const application = useApplication();
    const [value, setValue] = useState(application.getPreference(preference, PrefDefaults[preference]));
    useEffect(() => {
        return application.addEventObserver(async () => {
            const latestValue = application.getPreference(preference, PrefDefaults[preference]);
            setValue(latestValue);
        }, ApplicationEvent.PreferencesChanged);
    }, [application, preference]);
    return value;
}
